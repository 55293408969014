<template>
  <v-dialog
    v-model="dialog"
    max-width="700"
    persistent
  >
    <v-card class="rounded-xl">
      <v-card-title>
        <span class="text-h5">Rate: {{ item.title }}</span>
        <v-spacer />
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-rating
          v-model="rating"
          hover
          half-increments
          color="red"
          length="5"
          density="compact"
          class="d-inline-block"
          size="26"
        />
        <span class="pl-3 grey--text text--lighten-2 font-weight-light text-caption">Rating is required</span>
        <br><br>
        <ClientOnly>
          <tiptap-vuetify
            v-model="content"
            :extensions="extensions"
            :toolbar-attributes="{ color: '#1e1e1e' }"
            class="rounded-xl"
            style="overflow:hidden; border: 1px solid #3b3b3b;"
            placeholder="Share your thoughts if you want to..."
          />
          <template #placeholder>
            Loading...
          </template>
        </ClientOnly>
        <div class="text-right">
          <v-checkbox
            v-model="containSpoiler"
            label="Is review contains spoiler?"
            class="d-inline-block"
            :disabled="!content"
          />
        </div>
      </v-card-text>
      <v-card-actions class="d-none d-sm-flex">
        <v-btn
          v-if="userRating"
          text
          @click="deleteReview"
        >
          Delete Rating
        </v-btn>
        <v-spacer />
        <v-btn
          text
          :disabled="!rating"
          @click="postReview"
        >
          Save
        </v-btn>
        |
        <v-btn
          text
          class="ml-0"
          :disabled="!rating"
          @click="postReviewAndSetStatus"
        >
          Save and Set Status
        </v-btn>
      </v-card-actions>
      <v-card-actions class="d-block d-sm-none text-right">
        <v-btn
          text
          class="ml-0"
          :disabled="!rating"
          @click="postReviewAndSetStatus"
        >
          Save and Set Status
        </v-btn>
        <br>
        <v-btn
          text
          :disabled="!rating"
          @click="postReview"
        >
          Just Save
        </v-btn>
        <br>
        <v-btn
          v-if="userRating"
          text
          class="red--text"
          @click="deleteReview"
        >
          Delete Rating
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";
import Review from "@/services/review";

export default {
  name: "Rate",
  components: { TiptapVuetify },
  data () {
    return {
      dialog: false,
      item: {},
      itemType: "",
      rateProvided: false,
      containSpoiler: false,
      showReviewEditor: true,
      userRating: null,
      rating: this.userRating ? this.userRating.rating : null,
      content: this.userRating ? this.userRating.content : "",
      extensions: [
        History,
        Blockquote,
        Underline,
        Strike,
        Bold,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Link,
        HorizontalRule,
        HardBreak,
      ],
    };
  },
  watch: {
    content (value) {
      if (value === "<p></p>") {
        this.content = "";
      }
    },
    dialog () {
      this.rating = this.userRating ? this.userRating.rating : null;
      this.content = this.userRating ? this.userRating.content : "";
      this.containSpoiler = this.userRating ? this.userRating.is_contains_spoilers : false;
    },
  },
  mounted () {
    this.$root.$on("openRateDialog", (item, itemType, userRating = undefined) => {
      this.item = item;
      this.itemType = itemType;
      this.userRating = userRating;
      this.dialog = true;
    });
  },
  methods: {
    async postReview () {
      if (this.userRating && this.userRating._id) {
        await this.updateReview(this.userRating._id);
      } else {
        await this.createReview();
      }
      this.dialog = false;
    },
    async postReviewAndSetStatus () {
      if (this.userRating && this.userRating._id) {
        await this.updateReview(this.userRating._id);
      } else {
        await this.createReview();
      }
      this.$root.$emit("openStatusDialog", this.item, this.itemType, this.rating);
      this.dialog = false;
    },
    async createReview () {
      const user = this.$store.state.user;
      const review = new Review(this.$axios, {
        Authorization: `Bearer ${user.token}`,
      });
      const params = {
        rating: this.rating,
        content: this.content,
        item_id: this.item._id,
        item_type: this.itemType,
        is_contains_spoilers: this.containSpoiler,
      };
      await review.create(params);
      this.$toast.info("Review created");
      this.$root.$emit("review-created", {
        item_id: this.item._id,
        item_type: this.itemType,
      });
    },
    async updateReview (reviewId) {
      const user = this.$store.state.user;
      const review = new Review(this.$axios, {
        Authorization: `Bearer ${user.token}`,
      });
      const params = {
        rating: this.rating,
        content: this.content,
        item_id: this.item._id,
        item_type: this.itemType,
        is_contains_spoilers: this.containSpoiler,
      };
      await review.update(reviewId, params);
      this.$toast.info("Review updated");
      this.$root.$emit("review-updated", {
        item_id: this.item._id,
        item_type: this.itemType,
      });
    },
    async deleteReview () {
      const user = this.$store.state.user;
      const review = new Review(this.$axios, {
        Authorization: `Bearer ${user.token}`,
      });
      await review.delete(this.userRating._id);
      this.dialog = false;
      this.userRating = null;
      this.$toast.info("Review deleted");
      this.$root.$emit("review-deleted", {
        item_id: this.item._id,
        item_type: this.itemType,
      });
    },
  },
};
</script>
