import Base from "./base";

export default class Review extends Base {
  async getReviews (item_id, item_type, withContent = false, offset = 0, limit = 5) {
    return await this.makeGetRequest("/reviews", { item_id, item_type, only_with_content: withContent, offset, limit });
  }

  async getUserReview (item_id, item_type, user_id, withContent = false) {
    const response = await this.makeGetRequest("/reviews", { item_id, item_type, user_id, only_with_content: withContent });
    return response.total > 0 ? response.results[0] : null;
  }

  async getReviewsByUser (user_id) {
    return await this.makeGetRequest("/reviews", { user_id });
  }

  async getReviewsByItemAndUser (item_id, item_type, user_id) {
    const response = await this.makeGetRequest("/reviews", { item_id, item_type, user_id });
    return response.total > 0 ? response.results[0] : null;
  }

  async getReview (reviewId) {
    return await this.makeGetRequest(`/reviews/${reviewId}`);
  }

  async create (params) {
    return await this.makePostRequest("/reviews", params);
  }

  async update (reviewId, params) {
    return await this.makePatchRequest(`/reviews/${reviewId}`, params);
  }

  async delete (reviewId) {
    return await this.makeDeleteRequest(`/reviews/${reviewId}`);
  }

  async likeReview (reviewId, like) {
    return await this.makePostRequest(`/reviews/${reviewId}/likes`, { is_liked: like });
  }
}
